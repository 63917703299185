const cityDestinations = {
    'г.Москва': '-1275551',
    'г.Санкт-Петербург': '-1123300',
    'г.Дмитров': '123589350',
    'г.Краснодар': '12358062',
    'г.Казань': '-2133463',
    'г.Бишкек': '286'
};

export default cityDestinations;
